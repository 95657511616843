@import '@angular/material/prebuilt-themes/indigo-pink.css';

mat-form-field {
	width: 100%;
}

mat-form-field.mat-form-field {
	font-size: 16px;
}

mat-form-field {
	margin-bottom: .25rem;
}

.mat-hint {
	font-size: 11px;
}

.material-icons.md-close {
	font-size: 30px;
	margin-top: -1rem;
}

@mixin styling($map) {
	body {
		background-color: #202020;
	}

	a {
		cursor: pointer;
		color: #333;
		text-align: left;
	}

	ul {
		li {
			font-size: 16px;
			text-align: justify;
		}
	}

	.highlight-link {
		color: #395f83;
		font-weight: map-get($map, 'bold');
	}

	.footer {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		color: #404040;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 1rem;
		padding-top: .5rem;
		font-size: map-get($map, 'font-size-sm');
		border: 1px solid #b0b0b0;
		background-color: #e0e0e0;
	}

	.footer p:first-child {
		font-weight: map-get($map, 'bold');
	}

	.footer .italic {
		padding-top: .25rem;
		font-style: italic;
	}

	.footer .get-started {
		font-weight: map-get($map, 'bold');
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 8px 0;
	}

	p {
		font-size: map-get($map, 'font-size-md');
		margin: 0;
	}

	span {
		font-size: map-get($map, 'font-size-md');
	}

	.info-card-content-section p {
		margin-bottom: .5rem;
	}

	.important-banner {
		background-color: burlywood;
		padding-left: 1rem;
		padding-top: .5rem;
		padding-bottom: .5rem;
		padding-right: 1rem;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-align: justify;
	}
	.important-banner img {
		padding-right: .5rem;
		color: rgb(201, 138, 57);
	}
	.important-banner p {
		padding-bottom: 0rem;
	}

	.footer {
		b {
			font-size: 16px;
			font-weight: 500;
		}
		text-align: justify;
	}

	.footer-services {
		font-size: 16px;
		div {
			padding-bottom: .5rem;
		}
	}

	app-content-card {
		img {
			padding-bottom: 1rem;
			width: 100%;
		}

		a {
			font-size: 16px;
			text-align: left;
		}

		div:not(.loader) {
			padding-left: 1rem;
			padding-right: 1rem;
			padding-bottom: 1rem;
		}

		.div-list {
			margin-left: -1rem;
			margin-right: -1rem;
			text-align: justify;
		}

		b {
			font-size: 16px;
			font-weight: 500;
		}

		h1 {
			font-size: 16px;
			font-weight: 500;
			margin-top: 0rem;
			margin-bottom: 0rem;
		}

		p {
			padding-bottom: 1rem;
			padding-top: 1rem;
			text-align: justify;
		}
	}

	app-form-card {
		form {
			padding-left: 1rem;
			padding-right: 1rem;
			padding-bottom: 1rem;
			max-width: 420px;
		}

		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 50%;
		}
	}

	app-info-card .info-card {
		background-color: #e8e8e8;
		border-bottom-right-radius: 25px;
		border-top-right-radius: 25px;
		width: calc(100%);
		padding-top: 1rem;
		padding-bottom: 1rem;
		display: flex;
		align-items: center;
		margin-bottom: .25rem;
	}

	app-page-header + app-info-card .info-card {
		border-top-right-radius: 0px;
	}

	app-page-header + app-important-banner + app-info-card .info-card {
		border-top-right-radius: 0px;
	}

	app-page-header + app-drop-in-free-lesson-important-banner + app-info-card .info-card {
		border-top-right-radius: 0px;
	}

	app-info-card-content-section {
		width: 100%;
	}

	html,
	body {
		height: 100%;
	}
	body {
		margin: 0;
		font-family: "Segoe UI", "Helvetica Neue", sans-serif;
	}
}

@media screen and (min-width: 1px) {
	@include styling((
		bold: 600,
		font-size-sm: 12px,
		font-size-md: 16px,
		font-size-lg: 18px,
	));
}

@media screen and (min-width: 960px) {
	@include styling((
		bold: 600,
		font-size-sm: 12px,
		font-size-md: 16px,
		font-size-lg: 18px,
	));
}

@supports (-webkit-touch-callout: none) {
	app-content-card {
		b {
			font-weight: 600;
		}
	}

	.footer {
		b {
			font-size: 16px;
			font-weight: 600;
		}
	}
}

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}
